import { Modal, Button, Select } from "antd";
import { hideModal } from "../../../redux/reducers/modalSlice";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { setAppLoader } from "../../../redux/reducers/loaderSlice";
import { errorToastMessage, toastMessage } from "../../../helpers/toastMessage";
import http from "../../../http";
import { useEffect, useState } from "react";
import { getStudyId } from "../../../helpers/study";

const ModifySiteAccessModal = () => {
  const dispatch = useAppDispatch();
  const { show, id, sites, callback } = useAppSelector(
    (state) => state.modal.modalProps
  );
  const [options, setOptions] = useState<any[]>();
  const [selected, setSelected] = useState<any[]>(sites);

  const closeHandler = () => {
    dispatch(hideModal());
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        dispatch(setAppLoader(true));
        let url = `/study/${getStudyId()}/sites?page=1&size=50`;
        const res = await http.get(url);
        const rows = res.data.data.rows.map((row: any) => {
          return {
            value: row.id,
            label: row.name,
          };
        });
        setOptions(rows);
        dispatch(setAppLoader(false));
      } catch (err) {
        dispatch(setAppLoader(false));
        errorToastMessage(err as Error);
      }
    };
    fetchData();
  }, [setOptions, dispatch]);

  const submitHandler = async () => {
    try {
      if (selected.length < 1) {
        toastMessage("warning", "Select atleast one site");
        return;
      }
      dispatch(setAppLoader(true));
      const data = {
        siteIds: selected,
        userId: id,
      };
      const res = await http.patch(`/admins/sites`, data);
      toastMessage("success", res.data.message);
      callback();
      dispatch(hideModal());
    } catch (err) {
      dispatch(setAppLoader(false));
      errorToastMessage(err as Error);
    }
  };

  return (
    <Modal
      title={`Modify Site Access`}
      visible={show}
      onCancel={closeHandler}
      width={"45rem"}
      footer={null}
    >
      <div>
        <div>
          <div className="text-secondary font-m fw-medium mb-1">
            Select Sites
          </div>
          <Select
            mode="multiple"
            options={options}
            className="custom-select-field"
            size="large"
            value={selected}
            onChange={(val) => setSelected(val)}
            placeholder="Select sites"
          />
        </div>
        <div className="d-flex mt-5">
          <Button
            type="primary"
            htmlType="button"
            style={{ height: "40px", borderRadius: "12px" }}
            className="me-4"
            onClick={submitHandler}
          >
            <span className="fw-semibold font-sm">Save</span>
          </Button>
          <Button
            style={{ height: "40px", borderRadius: "12px" }}
            className="me-4"
            onClick={closeHandler}
          >
            <span className="fw-semibold font-sm">Cancel</span>
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default ModifySiteAccessModal;
