import { Modal, Button, Select } from "antd";
import { hideModal } from "../../../redux/reducers/modalSlice";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { toastMessage } from "../../../helpers/toastMessage";
import { useState } from "react";
import { addPrescreenedParticipant } from "../../../redux/actions/adminAction";

const AddPrescreeningModal = () => {
  const dispatch = useAppDispatch();
  const { show } = useAppSelector((state) => state.modal.modalProps);
  const { sites: siteList } = useAppSelector((state) => state.user);
  const [selected, setSelected] = useState<any>(null);

  const closeHandler = () => {
    dispatch(hideModal());
  };

  const submitHandler = async () => {
    if (!selected) {
      toastMessage("warning", "Please Select a site to assign");
      return;
    }
    dispatch(addPrescreenedParticipant(selected));
  };

  return (
    <Modal
      title={`Add Prescreening Participant`}
      visible={show}
      onCancel={closeHandler}
      width={"45rem"}
      footer={null}
    >
      <div>
        <div>
          <div className="text-secondary font-m fw-medium mb-1">
            Select Site
          </div>
          <Select
            value={selected}
            onChange={(val) => setSelected(val)}
            className="custom-select-field"
            size="large"
          >
            {siteList.map((site) => (
              <Select.Option value={site.value} key={site.value}>
                {site.label}
              </Select.Option>
            ))}
          </Select>
        </div>
        <div className="d-flex mt-5">
          <Button
            type="primary"
            htmlType="button"
            style={{ height: "40px", borderRadius: "12px" }}
            className="me-4"
            onClick={submitHandler}
          >
            <span className="fw-semibold font-sm">Create</span>
          </Button>
          <Button
            style={{ height: "40px", borderRadius: "12px" }}
            className="me-4"
            onClick={closeHandler}
          >
            <span className="fw-semibold font-sm">Cancel</span>
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default AddPrescreeningModal;
