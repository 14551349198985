export declare type ROLE = "Therapist" | "Admin";
// 1. Principal Investigator
// 2. Study Coordinator / CRF data collector
// 3. Recruiter
// 4. Subject
// 5. Data analyst
const roles = {
  SystemAdmin: {
    be_value: "system_admin",
    fe_value: "Master Admin",
  },
  StudyCoordinator: {
    be_value: "study_coordinator",
    fe_value: "Study coordinator",
  },
  Facilitator: {
    be_value: "facilitator",
    fe_value: "Facilitator",
  },
  investigator: {
    be_value: "investigator",
    fe_value: "Investigator",
  },
  DataManager: {
    be_value: "data_manager",
    fe_value: "Data Monitor",
  },
  DataEntry: {
    be_value: "data_entry",
    fe_value: "Data Entry",
  },
  Rater: {
    be_value: "rater",
    fe_value: "Rater",
  },
  TechnicalSupport: {
    be_value: "technical_support",
    fe_value: "Technical Support",
  },
  EducationContentManager: {
    be_value: "education_content_manager",
    fe_value: "Education Content Manager",
  },
};

export const getBERoleName = (type: string) => {
  let role = "";
  Object.entries(roles).forEach(([_1, val]: any) => {
    if (type === val.fe_value) {
      role = val.be_value;
    }
  });
  return role;
};

export default roles;
