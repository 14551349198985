import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AdminUserPayload } from "../../types";

export interface AdministratorState {
  searchTerm: string;
  adminUsers: any[];
  adminCount: number;
  toggleLoader: boolean;
  timings: any;
  therapistInfo: any;
  sites: string;
}

const initialState: AdministratorState = {
  searchTerm: "",
  adminUsers: [],
  adminCount: 0,
  toggleLoader: false,
  timings: {},
  therapistInfo: null,
  sites: "all",
};

export const administratorSlice = createSlice({
  name: "administrator",
  initialState,
  reducers: {
    setAdminSearchTerm: (state, action: PayloadAction<string>) => {
      state.searchTerm = action.payload;
    },
    setAdminSite: (state, action: PayloadAction<string>) => {
      state.sites = action.payload;
    },
    setAdminUsers: (state, action: PayloadAction<AdminUserPayload>) => {
      state.adminUsers = action.payload.users;
      state.adminCount = action.payload.count;
    },
    setAdminToggleLoader: (state) => {
      state.toggleLoader = !state.toggleLoader;
    },
    setTherapistTimings: (state, action: PayloadAction<any>) => {
      state.timings = action.payload.timings;
    },
    setTherapistInfo: (state, action: PayloadAction<any>) => {
      state.therapistInfo = action.payload;
    },
  },
});

export const {
  setAdminSearchTerm,
  setAdminUsers,
  setAdminToggleLoader,
  setTherapistTimings,
  setTherapistInfo,
  setAdminSite,
} = administratorSlice.actions;

export default administratorSlice.reducer;
