import { useAppDispatch } from "../../redux/hooks";
import { useEffect, useState } from "react";
import { Button, Dropdown, Menu, Table } from "antd";
import { CustomPagination, PaginationText } from "../Common/TableUtils";
import { errorToastMessage } from "../../helpers/toastMessage";
import { setAppLoader } from "../../redux/reducers/loaderSlice";
import http from "../../http";
import { DotsVerticalIcon, PencilIcon, PlusIcon } from "@heroicons/react/solid";
import { setModalDetails } from "../../redux/reducers/modalSlice";
import { getStudyId } from "../../helpers/study";

const Sites = () => {
  const dispatch = useAppDispatch();
  const [current, setCurrent] = useState(1);
  const [total, setTotal] = useState(0);
  const [data, setData] = useState<any[]>([]);
  const [toggle, setToggle] = useState(false);

  useEffect(() => {
    const fetch = async (page: number) => {
      try {
        dispatch(setAppLoader(true));
        let url = `/study/${getStudyId()}/sites?page=${page}&size=8`;
        const res = await http.get(url);
        const rows = res.data.data.rows.map((row: any) => {
          return {
            id: row.id,
            name: row.name,
            abbreviation: row.abbreviation,
            country: row.country,
          };
        });
        setTotal(res.data.data.count);
        setData(rows);
        dispatch(setAppLoader(false));
      } catch (err) {
        dispatch(setAppLoader(false));
        errorToastMessage(err as Error);
      }
    };
    fetch(current);
  }, [dispatch, setTotal, setData, current, toggle]);

  const handleEdit = (data: any) => {
    dispatch(
      setModalDetails({
        type: "ADD_SITES_MODAL",
        modalProps: {
          show: true,
          callback: () => {
            setToggle((pre) => !pre);
          },
          siteData: data,
        },
      })
    );
  };

  const menu = (data: any) => (
    <Menu style={{ minWidth: "225px", borderRadius: "12px" }}>
      <Menu.Item key="1" onClick={() => handleEdit(data)}>
        <PencilIcon className="hero-icon me-3" />
        <span>Edit</span>
      </Menu.Item>
    </Menu>
  );

  const columns = [
    {
      title: "Site Name",
      key: "name",
      dataIndex: "name",
    },
    {
      title: "Abbreviation",
      dataIndex: "abbreviation",
      key: "abbreviation",
    },
    {
      title: "Country",
      dataIndex: "country",
      key: "country",
    },
    {
      title: "",
      width: "10%",
      className: "text-end",
      render: (_1: any, record: any) => {
        return (
          <span onClick={(e) => e.stopPropagation()} className="d-inline-block">
            <Dropdown
              overlay={menu(record)}
              placement="bottomRight"
              trigger={["click"]}
            >
              <DotsVerticalIcon
                className="hero-icon cp"
                style={{ color: "#71717A", marginLeft: "auto" }}
              />
            </Dropdown>
          </span>
        );
      },
    },
  ];

  const onTableChange = async (pagination: any, filters: any) => {
    if (pagination.current) {
      setCurrent(pagination.current);
    }
  };

  const handleButtonClick = () => {
    dispatch(
      setModalDetails({
        type: "ADD_SITES_MODAL",
        modalProps: {
          show: true,
          callback: () => {
            setToggle((pre) => !pre);
          },
        },
      })
    );
  };

  return (
    <div className="cms-wrapper">
      <div className="content-header">
        <div className="font-m fw-semibold font-l me-5 text-secondary">
          Sites
        </div>
        <span style={{ marginLeft: "auto" }}></span>
        <Button
          type="primary"
          onClick={handleButtonClick}
          className="d-flex align-items-center me-4"
          style={{ height: "40px", borderRadius: "12px" }}
        >
          <PlusIcon className="hero-small-icon me-2" />
          <span className="fw-semibold font-sm">Add Site</span>
        </Button>
      </div>
      <div className="modules-wrapper question-module-wrapper">
        <Table
          dataSource={data}
          columns={columns}
          className="singer-custom-table"
          onChange={onTableChange}
          rowKey={(record) => record.id}
          pagination={{
            current: current,
            total: total,
            showSizeChanger: false,
            pageSize: 8,
            hideOnSinglePage: true,
            className: "singer-table-pagination",
            itemRender: CustomPagination,
            showTotal: PaginationText,
            position: ["bottomLeft"],
          }}
        />
      </div>
    </div>
  );
};

export default Sites;
