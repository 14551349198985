import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  AdminUserPayload,
  ParticipantGroupPayload,
  UserPayload,
} from "../../types";

export interface ParticipantState {
  searchTerm: string;
  searchType: string;
  screenedParticipantUsers: any[];
  screenedParticipantCount: number;
  preScreenedParticipantUsers: any[];
  preScreenedParticipantCount: number;
  groupCount: number;
  groups: any[];
  filterParticipants: any[];
  filterParticipantsCount: number;
  toggleLoader: boolean;
  selectedParticipants: string[];
  headers: any[];
  crfHeaders: any[];
  proHeaders: any[];
  site: string;
}

const initialState: ParticipantState = {
  searchTerm: "",
  searchType: "screenId",
  screenedParticipantUsers: [],
  screenedParticipantCount: 0,
  preScreenedParticipantUsers: [],
  preScreenedParticipantCount: 0,
  toggleLoader: false,
  groups: [],
  groupCount: 0,
  filterParticipants: [],
  filterParticipantsCount: 0,
  selectedParticipants: [],
  headers: [],
  crfHeaders: [],
  proHeaders: [],
  site: "all",
};

export const participantSlice = createSlice({
  name: "participant",
  initialState,
  reducers: {
    setParticipantSearchTerm: (state, action: PayloadAction<string>) => {
      state.searchTerm = action.payload;
    },
    setParticipantSite: (state, action: PayloadAction<string>) => {
      state.site = action.payload;
    },
    setParticipantSearchType: (
      state,
      action: PayloadAction<{ type: string }>
    ) => {
      state.searchType = action.payload.type;
    },
    setScreenedParticipantUsers: (
      state,
      action: PayloadAction<{
        users: any[];
        count: number;
        proHeaders: any[];
        crfHeaders: any[];
      }>
    ) => {
      state.screenedParticipantUsers = action.payload.users;
      state.screenedParticipantCount = action.payload.count;
      state.proHeaders = action.payload.proHeaders;
      state.crfHeaders = action.payload.crfHeaders;
    },
    setParticipantGroups: (
      state,
      action: PayloadAction<ParticipantGroupPayload>
    ) => {
      state.groups = action.payload.groups;
      state.groupCount = action.payload.count;
    },
    setFilterParticipants: (state, action: PayloadAction<AdminUserPayload>) => {
      state.filterParticipants = action.payload.users;
      state.filterParticipantsCount = action.payload.count;
    },
    setPreScreenedParticipantUsers: (
      state,
      action: PayloadAction<UserPayload>
    ) => {
      state.preScreenedParticipantUsers = action.payload.users;
      state.preScreenedParticipantCount = action.payload.count;
      state.headers = action.payload.headers;
    },
    toggleParticipantLoader: (state) => {
      state.toggleLoader = !state.toggleLoader;
    },
    setSelectedParticipants: (state, action: PayloadAction<string[]>) => {
      state.selectedParticipants = action.payload;
    },
  },
});

export const {
  setPreScreenedParticipantUsers,
  setScreenedParticipantUsers,
  setParticipantSearchTerm,
  toggleParticipantLoader,
  setParticipantGroups,
  setFilterParticipants,
  setSelectedParticipants,
  setParticipantSearchType,
  setParticipantSite,
} = participantSlice.actions;

export default participantSlice.reducer;
