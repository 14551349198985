export const canAmendAppointments = (role: string) => {
  return [
    "system_admin",
    "study_coordinator",
    "education_content_manager",
    "facilitator",
  ].includes(role);
};

export const canAddParticipant = (role: string) => {
  return ["system_admin", "study_coordinator"].includes(role);
};

export const canExportData = (role: string) => {
  return ["system_admin", "data_manager"].includes(role);
};

export const AmendStudyRoles = [
  "study_coordinator",
  "facilitator",
  "data_manager",
  "system_admin",
  "rater",
];
export const canAmendStudy = (role: string) => {
  return AmendStudyRoles.includes(role);
};

export const canAddExercise = (role: string) => {
  return [
    "system_admin",
    "study_coordinator",
    "education_content_manager",
    "facilitator",
  ].includes(role);
};

export const hasBlindingAccess = (role: string) => {
  return ["system_admin", "study_coordinator", "facilitator"].includes(role);
};

export const canAmendParticipantDetails = (role: string) => {
  return !["education_content_manager", "technical_support"].includes(role);
};

export const canAmendQueries = (role: string) => {
  return !["education_content_manager", "technical_support"].includes(role);
};

export const hasAdminAccess = (role: string) => {
  return ["system_admin", "data_manager"].includes(role);
};

export const hasCMSAccess = (role: string) => {
  return ["education_content_manager", "system_admin"].includes(role);
};

export const hasSubGroupAccess = (role: string) => {
  return [
    "system_admin",
    "study_coordinator",
    "facilitator",
    "investigator",
  ].includes(role);
};
