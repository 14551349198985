import { Modal, Button, Input, Select } from "antd";
import { Formik } from "formik";
import { errorToastMessage, toastMessage } from "../../../helpers/toastMessage";
import { hideModal } from "../../../redux/reducers/modalSlice";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { setAppLoader } from "../../../redux/reducers/loaderSlice";
import http from "../../../http";
import { getStudyId } from "../../../helpers/study";
import { fetchSites } from "../../../redux/actions/adminAction";

let yup = require("yup");

let schema = yup.object().shape({
  name: yup.string().trim().required("Name is Required"),
  abbreviation: yup.string().trim().required("Abbreviation is Required"),
  country: yup.string().trim().required("Country is Required"),
});

const countries = [
  "United States",
  "United Kingdom",
  "Australia",
  "India",
  "Singapore",
];

const AddSitesModal = (props: any) => {
  const dispatch = useAppDispatch();
  const { siteData, show, callback } = useAppSelector(
    (state) => state.modal.modalProps
  );

  const closeHandler = () => {
    dispatch(hideModal());
  };

  const submitHandler = async (values: any) => {
    try {
      dispatch(setAppLoader(true));
      const formData = { ...values };
      let res;
      if (siteData) {
        res = await http.put(
          `/study/${getStudyId()}/sites/${siteData.id}`,
          formData
        );
      } else {
        res = await http.post(`/study/${getStudyId()}/sites`, formData);
      }
      toastMessage("success", res.data.message);
      dispatch(setAppLoader(false));
      dispatch(hideModal());
      dispatch(fetchSites());
      callback();
    } catch (err) {
      dispatch(setAppLoader(false));
      errorToastMessage(err as Error);
    }
  };

  return (
    <Modal
      title={siteData ? `Edit ${siteData.name}` : "Add new Site"}
      visible={show}
      onCancel={closeHandler}
      width={"45rem"}
      footer={null}
    >
      <div className="add-education">
        <Formik
          initialValues={{
            name: props?.siteData?.name || "",
            abbreviation: props?.siteData?.abbreviation || "",
            country: props?.siteData?.country || "",
          }}
          validationSchema={schema}
          onSubmit={(values) => {
            submitHandler(values);
          }}
        >
          {({
            values,
            handleSubmit,
            handleChange,
            handleBlur,
            setFieldValue,
            errors,
            touched,
          }) => (
            <form onSubmit={handleSubmit} className="m-0">
              <div>
                <div className="text-secondary font-m fw-medium mb-1">Name</div>
                <Input
                  name="name"
                  placeholder="Name"
                  onChange={handleChange}
                  className="singer-input"
                  value={values.name}
                  onBlur={handleBlur}
                />
                <div className="input-error">
                  {errors.name && touched.name && errors.name}
                </div>
              </div>
              <div>
                <div className="text-secondary font-m fw-medium mb-1">
                  Abbreviation
                </div>
                <Input
                  name="abbreviation"
                  placeholder="Abbreviation"
                  onChange={handleChange}
                  className="singer-input"
                  value={values.abbreviation}
                  onBlur={handleBlur}
                />
                <div className="input-error">
                  {errors.abbreviation &&
                    touched.abbreviation &&
                    errors.abbreviation}
                </div>
              </div>
              <div>
                <div className="text-secondary font-m fw-medium mb-1">
                  Country
                </div>
                <Select
                  className="custom-select-field"
                  size="large"
                  style={{ width: "100%" }}
                  value={values.country}
                  onChange={(val) => {
                    setFieldValue(`country`, val);
                  }}
                >
                  {countries.map((country) => {
                    return (
                      <Select.Option value={country}>
                        <span style={{ textTransform: "capitalize" }}>
                          {country}
                        </span>
                      </Select.Option>
                    );
                  })}
                </Select>
                <div className="input-error">
                  {errors.country && touched.country && errors.country}
                </div>
              </div>
              <div className="d-flex mt-3">
                <Button
                  type="primary"
                  htmlType="submit"
                  style={{ height: "40px", borderRadius: "12px" }}
                  className="me-4"
                >
                  <span className="fw-semibold font-sm">Save</span>
                </Button>
                <Button
                  style={{ height: "40px", borderRadius: "12px" }}
                  className="me-4"
                  onClick={closeHandler}
                >
                  <span className="fw-semibold font-sm">Cancel</span>
                </Button>
              </div>
            </form>
          )}
        </Formik>
      </div>
    </Modal>
  );
};

export default AddSitesModal;
