import { AxiosResponse } from "axios";
import roles from "../../constants/roles";
import { errorToastMessage, toastMessage } from "../../helpers/toastMessage";
import http from "../../http";
import {
  setAdminToggleLoader,
  setAdminUsers,
  setTherapistInfo,
  setTherapistTimings,
} from "../reducers/administratorSlice";
import {
  setScreenedParticipantUsers,
  setFilterParticipants,
  setPreScreenedParticipantUsers,
  setParticipantGroups,
  toggleParticipantLoader,
} from "../reducers/participantSlice";
import { setAppLoader } from "../reducers/loaderSlice";
import { hideModal } from "../reducers/modalSlice";
import { AppThunk } from "../store";
import moment from "moment";
import { setUserSites } from "../reducers/userSlice";

export const fetchAdministratorDetails =
  (
    type: string,
    searchTerm: string,
    page: number,
    filter: string,
    order: any,
    site: string
  ): AppThunk =>
  async (dispatch) => {
    dispatch(setAppLoader(true));
    let url = `/admins?page=${page}&size=8&role=${type}`;
    if (filter) {
      url += `&status=${filter}`;
    }
    if (order) {
      url += `&orderBy=${order.orderBy}&sortBy=${order.sortBy}`;
    }
    if (searchTerm) {
      url += `&search=${searchTerm}`;
    }
    if (site && site !== "all") {
      url += `&siteId=${site}`;
    }
    const res = await http.get(url);
    const data = res.data.data;
    const users = data.rows.map((user: any) => {
      return {
        id: user.id,
        firstName: user.firstName,
        lastName: user.lastName,
        status: user.status,
        email: user.email,
        createdAt: moment(user.createdAt).format("DD/MM/YYYY"),
        sites: ["system_admin", "data_manager"].includes(type)
          ? ["All"]
          : user?.sites?.map((site: any) => {
              return site?.site?.name || "site";
            }) || [],
        siteIds: user?.sites?.map((site: any) => {
          return site?.site?.id;
        }),
      };
    });
    dispatch(
      setAdminUsers({
        users: users,
        count: data.count,
      })
    );
    dispatch(setAppLoader(false));
  };

export const addAdministrator =
  (form: any): AppThunk =>
  async (dispatch) => {
    dispatch(setAppLoader(true));
    const res: AxiosResponse = await http.post(`/admins`, form);
    toastMessage("success", res.data.message);
    dispatch(hideModal());
    dispatch(setAdminToggleLoader());
  };

export const addPrescreenedParticipant =
  (siteId: string): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(setAppLoader(true));
      const res: AxiosResponse = await http.post(`/users/participant`, {
        siteId: siteId,
      });
      toastMessage("success", res.data.message);
      dispatch(toggleParticipantLoader());
      dispatch(hideModal());
    } catch (err) {
      dispatch(setAppLoader(false));
      errorToastMessage(err as Error);
    }
  };

export const fetchSites = (): AppThunk => async (dispatch) => {
  try {
    const res = await http.get("/admins/sites");
    const sites = res.data.data.map((site: any) => {
      return {
        value: site.siteId,
        label: site?.site?.name || "site",
      };
    });
    dispatch(setUserSites(sites));
  } catch (err) {
    console.log(err);
  }
};

export const fetchParticipantDetails =
  (
    type: string,
    searchTerm: string,
    page: number,
    filter?: string,
    sort?: any,
    site?: string
  ): AppThunk =>
  async (dispatch, getState) => {
    dispatch(setAppLoader(true));
    let url = `/users?page=${page}&size=8&stage=${
      type === "pre-screened" ? 0 : 1
    }`;
    if (sort) {
      url += `&orderBy=${sort.orderBy}&sortBy=${sort.sortBy}`;
    }
    if (site && site !== "all") {
      url += `&siteId=${site}`;
    }
    if (searchTerm) {
      let searchType = getState().participant.searchType;
      if (type === "pre-screened") {
        searchType = "screenId";
      }
      url += `&${searchType}=${searchTerm}`;
    }
    if (filter && type === "screened") {
      url += `&group=${filter}`;
    } else if (filter) {
      url += `&archived=${filter}`;
    }
    const res = await http.get(url);
    const data = res.data.data;
    const headers: any[] = res.data.data.headers.map((header: any) => {
      return {
        id: header.questionnaireid + "#" + header.phaseid,
        qid: header.questionnaireid,
        pid: header.phaseid,
        title: `${header.questionnairename} (${header.phasename})`,
        total: +header.qs_total_count,
        questioncategory: header.questionnairecategory,
        isScreening: header.isScreening,
      };
    });
    const proHeaders: any[] = [];
    const crfHeaders: any[] = [];
    res.data.data.headers.forEach((header: any) => {
      if (header.questionnairecategory === 1) {
        crfHeaders.push({
          id: header.questionnaireid + "#" + header.phaseid,
          qid: header.questionnaireid,
          pid: header.phaseid,
          title: `${header.questionnairename} (${header.phasename})`,
          total: +header.qs_total_count,
          questioncategory: header.questionnairecategory,
          isScreening: header.isScreening,
        });
      } else {
        proHeaders.push({
          id: header.questionnaireid + "#" + header.phaseid,
          qid: header.questionnaireid,
          pid: header.phaseid,
          title: `${header.questionnairename} (${header.phasename})`,
          total: +header.qs_total_count,
          questioncategory: header.questionnairecategory,
          isScreening: header.isScreening,
        });
      }
    });
    const responseObj: any = {};
    if (type === "screened") {
      res.data.data.responses.forEach((response: any) => {
        responseObj[response.patientId] = response.responses;
      });
    }
    const users = data.users.users.map((user: any) => {
      const additionalHeaders: any = {};
      if (type === "pre-screened") {
        let count = 0;
        headers.forEach((header) => {
          const key = header.qid + "#" + header.pid;
          const attempt = user.programQuestionnaires.find((att: any) => {
            return (
              att.phaseId === header.pid && header.qid === att.questionnaireId
            );
          });
          if (attempt) {
            additionalHeaders[key] = attempt.status === "completed" ? 100 : 0;
            if (additionalHeaders[key] === 100) {
              count++;
            }
          } else {
            additionalHeaders[key] = 0;
          }
        });
        const completion = headers.length
          ? +((count * 100) / headers.length).toFixed(2)
          : 0;
        return {
          id: user.id,
          createdAt: moment(user.createdAt).format("DD/MM/YYYY"),
          screenId: user.screenId,
          isLocked: user?.participant?.isLocked || false,
          status: user.archived ? "inactive" : "active",
          site: user?.site?.name,
          completion: completion,
          ...additionalHeaders,
        };
      } else {
        let crfCount = 0;
        let eproCount = 0;
        headers.forEach((header) => {
          const key = header.qid + "#" + header.pid;
          const formCompletion = user.programQuestionnaires.find((att: any) => {
            return (
              att.phaseId === header.pid &&
              header.qid === att.questionnaireId &&
              att.status === "completed"
            );
          });
          if (formCompletion) {
            header.questioncategory === 1 ? crfCount++ : eproCount++;
            additionalHeaders[key] = 100;
          } else {
            const attempts = responseObj[user.id];
            if (attempts) {
              const attempt = attempts.find((att: any) => {
                return (
                  att.phaseId === header.pid &&
                  header.qid === att.questionnaireId
                );
              });
              if (attempt) {
                const percent = header.total
                  ? +((attempt.responseCount * 100) / header.total).toFixed(2)
                  : 0;
                additionalHeaders[key] = percent;
                if (percent === 100) {
                  header.questioncategory === 1 ? crfCount++ : eproCount++;
                }
              } else {
                additionalHeaders[key] = 0;
              }
            } else {
              additionalHeaders[key] = 0;
            }
          }
        });
        const crfTotal = headers.filter(
          (header) => header.questioncategory === 1
        ).length;
        const eproTotal = headers.filter(
          (header) => header.questioncategory === 2
        ).length;
        const crfCompletion = crfTotal
          ? +((crfCount * 100) / crfTotal).toFixed(2)
          : 0;
        const eproCompletion = eproTotal
          ? +((eproCount * 100) / eproTotal).toFixed(2)
          : 0;
        return {
          id: user.id,
          createdAt: moment(user?.participant?.createdAt).format("DD/MM/YYYY"),
          screenId: user.screenId,
          subjectId: user?.participant?.subjectId,
          isLocked: user?.participant?.isLocked || false,
          status: user.archived ? "inactive" : "active",
          nickname: user?.nickname || "",
          randomizationGroup: user?.participantGroups?.[0]?.name || "-",
          crfCompletion,
          site: user?.site?.name,
          eproCompletion,
          error: user?.error,
          errorReason: user?.errorReason,
          isDeviceRegistered: user?.participant?.isDeviceRegistered,
          subgroup: user?.participantSubGroupRelation?.subGroup?.name || null,
          lang: user?.lang,
          ...additionalHeaders,
        };
      }
    });
    if (type === "screened") {
      dispatch(
        setScreenedParticipantUsers({
          users: users,
          count: data.users.count,
          proHeaders: proHeaders,
          crfHeaders: crfHeaders,
        })
      );
    } else if (type === "pre-screened") {
      dispatch(
        setPreScreenedParticipantUsers({
          users: users,
          count: data.users.count,
          headers: headers,
        })
      );
    }
    dispatch(setAppLoader(false));
  };

//Using before this point

export const modifyAdminDetails =
  (form: any, id: string): AppThunk =>
  async (dispatch) => {
    dispatch(setAppLoader(true));
    const res: AxiosResponse = await http.patch(`/admins/${id}`, form);
    toastMessage("success", res.data.message);
  };

export const fetchTherapistTimings =
  (id: string): AppThunk =>
  async (dispatch) => {
    dispatch(setAppLoader(true));
    const res: AxiosResponse = await http.get(`/ap/timings?therapistId=${id}`);
    const timings = res.data.data;
    if (!timings) {
      dispatch(hideModal());
      toastMessage("error", "Slot unavailable");
      return;
    }
    const obj = {
      startTime: +(timings.onlineStartOffsets[0] || 0),
      endTime: +(
        timings.onlineEndOffsets[timings.onlineEndOffsets.length - 1] || 3600
      ),
      offlineStart: timings.offlineStartOffsets[0]
        ? +timings.offlineStartOffsets[0]
        : undefined,
      offlineEnd: timings.offlineEndOffsets[0]
        ? +timings.offlineEndOffsets[0]
        : undefined,
      interval: +timings.interval,
    };
    dispatch(
      setTherapistTimings({
        timings: obj,
      })
    );
    dispatch(setAppLoader(false));
  };

export const modifyTherapistSlot =
  (
    type: string,
    startTime: number,
    endTime: number,
    therapistId: string
  ): AppThunk =>
  async (dispatch, getState) => {
    dispatch(setAppLoader(true));
    const oldTimings = getState().administrator.timings;
    let formObj: any = {};
    formObj.userId = therapistId;
    if (type === "slot") {
      formObj.onlineStartOffsets = [startTime];
      formObj.onlineEndOffsets = [endTime];
      if (!oldTimings.offlineStart || !oldTimings.offlineEnd) {
        formObj.offlineStartOffsets = [];
        formObj.offlineEndOffsets = [];
      } else if (
        oldTimings.offlineStart < startTime ||
        oldTimings.offlineStart > endTime ||
        oldTimings.offlineEnd > endTime
      ) {
        formObj.offlineStartOffsets = [];
        formObj.offlineEndOffsets = [];
      } else {
        formObj.offlineStartOffsets = [oldTimings.offlineStart];
        formObj.offlineEndOffsets = [oldTimings.offlineEnd];
        formObj.onlineStartOffsets.push(oldTimings.offlineEnd);
        formObj.onlineEndOffsets = [oldTimings.offlineStart, endTime];
      }
    } else {
      formObj.onlineStartOffsets = [oldTimings.startTime, endTime];
      formObj.onlineEndOffsets = [startTime, oldTimings.endTime];
      formObj.offlineStartOffsets = [startTime];
      formObj.offlineEndOffsets = [endTime];
    }
    await http.patch("/ap/timings", formObj);
    dispatch(fetchTherapistTimings(therapistId));
  };

export const getTherapistInfo =
  (id: string): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(setAppLoader(true));
      const res: AxiosResponse = await http.get(`/users/${id}/misc`);
      dispatch(setTherapistInfo(res.data.data));
      dispatch(setAppLoader(false));
    } catch (err) {
      dispatch(setAppLoader(false));
      errorToastMessage(err);
    }
  };

export const updateTherapistInfo =
  (id: string, form: any): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(setAppLoader(true));
      const res: AxiosResponse = await http.put(`/users/${id}`, form);
      const role = localStorage.getItem("role");
      dispatch(setAppLoader(false));
      if (role === roles.StudyCoordinator["be_value"]) {
        toastMessage("success", "Therapist details updated successfully");
      } else {
        toastMessage("success", "Profile updated successfully");
        localStorage.setItem(
          "fullName",
          `${res.data.data.firstName} ${res.data.data.lastName}`
        );
        localStorage.setItem("profileImage", res.data.data.profileImage);
      }
    } catch (err) {
      dispatch(setAppLoader(false));
      errorToastMessage(err);
    }
  };

export const getUserGroups =
  (pageNo: number, site: string, searchItem?: string): AppThunk =>
  async (dispatch) => {
    dispatch(setAppLoader(true));
    const pageSize = 8;
    let url = `/participant_groups/subgroups?page=${pageNo}&size=${pageSize}`;
    if (site && site !== "all") {
      url += `&siteId=${site}`;
    }
    url = searchItem ? `${url}&q=${searchItem}` : url;
    const res = await http.get(url);
    dispatch(
      setParticipantGroups({
        groups: res.data.data.rows.map((group: any) => {
          return {
            id: group.id,
            name: group.name,
            lang: group.lang,
            participantCount: group.participantCount,
            adminRelations: group.adminRelations,
            createdAt: moment(group.createdAt).format("DD/MM/YYYY"),
            site: group?.site?.name,
            siteId: group?.siteId,
          };
        }),
        count: res.data.data.count,
      })
    );
    dispatch(setAppLoader(false));
  };

export const deleteParticipantGroups =
  (id: string): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(setAppLoader(true));
      const res = await http.delete(`/participant_groups/${id}`);
      toastMessage("success", res.data.message);
      dispatch(toggleParticipantLoader());
    } catch (err) {
      errorToastMessage(err);
      dispatch(setAppLoader(false));
    }
  };

export const participantApplyFilters =
  (pageNo: number, filterValues?: any, searchValues?: string): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(setAppLoader(true));
      const filters = filterValues !== undefined ? filterValues : [];
      const search = searchValues !== undefined ? searchValues : "";
      let body: any = {
        filters: filters,
      };
      if (search !== "") {
        body["search"] = search;
      }
      const res = await http.post(
        `/participants/apply_filters?page=${pageNo}&size=8`,
        body
      );
      dispatch(
        setFilterParticipants({
          users: res.data.data.participants,
          count: res.data.data.count,
        })
      );
      dispatch(setAppLoader(false));
    } catch (err) {
      dispatch(setAppLoader(false));
      errorToastMessage(err);
    }
  };

export const createParticipantGroup =
  (body: any): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(setAppLoader(true));
      const res = await http.post("/participant_groups", body);
      toastMessage("success", res.data.message);
      dispatch(setAppLoader(false));
    } catch (err) {
      dispatch(setAppLoader(false));
      errorToastMessage(err);
    }
  };
